import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard"
import IconCard from "../../../components/iconCard/iconCard"
import BrandLogo from "../../../images/logos/sarclisa-logo-new.svg";
import QuestionsIcon from "../../../images/icons/icon-questions-sarclisa.svg"
import HeroIcon from "../../../images/icons/icon-forms-sarclisa-hero.svg"


import EnrollmentForm from "../../../images/icons/elitek/enrollment-form.png";
import spanishEnrollmentForm from "../../../images/icons/elitek/spanish-enrollment-form.png";
import Officesupportbrochure from "../../../images/icons/hcp/office-support-brochure.png";
import PatientAssistanceProgramflashcard from "../../../images/icons/hcp/patient-assistance-program-flashcard.png";
import CopayProgramflashcard from "../../../images/icons/hcp/copay-program-flashcard.png";
import Alternatesourcesofcoverageflashcard from "../../../images/icons/hcp/alternate-sources-coverage-flashcard.png";
import Summarybenefitsflashcard from "../../../images/icons/hcp/summary-benefits-flashcard.png";
import CMSannotatedform from "../../../images/icons/hcp/cms-annotated-form.png";
import Priorauthorizationchecklist from "../../../images/icons/hcp/prior-authorization-checklist.png";
import Payerdenialflashcard from "../../../images/icons/hcp/payer-denial-flashcard.png";
import Letterofappealstemplate from "../../../images/icons/hcp/letter-appeals-template.png";
import Letterofmedicalnecessitytemplate from "../../../images/icons/hcp/letter-medical-necessity-template.png";
import Letterofmedicalexception from "../../../images/icons/hcp/letter-medical-exception-template.png";
import Billingandcodingguide from "../../../images/icons/hcp/billing-and-coding-guide.png";
import sarclisaBillingandcodingguide from "../../../images/icons/hcp/sarclisa-billing-and-coding-guide.png";
import CareASSISTbillingandcodingguide from "../../../images/icons/hcp/careassist-billing-and-coding-guide.png";
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"

const pageContent = () => (
  <Layout pageid="page-forms">
    <SEO
      title="Find CareASSIST resources and additional support options for eligible patients prescribed SARCLISA® (isatuximab-irfc) | HCP Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), HCP Site, Resource Support"
      description="Download a brochure, a template, or explore online resources offering additional support for SARCLISA from CareASSIST. See full Prescribing Info"
    />
    <Hero
      brandLogo={BrandLogo}
      headline="Resource support"
      copy="We help connect patients to independent support organizations and resources throughout their treatment journey"
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Resource Support Icon"
      brandAlt="Sarclisa logo"
    />
    <section className="content-section mobile-p-t-20 forms-resources">
    <blockquote className="sarclisa mb-2 sm-max-width p-t-40">
      <ExpandingCard
        cardClass="full sarclisa"
        mainContent={() => (
          <div className="expanding-card-content header">
            <h2>CareASSIST enrollment and program overview</h2>
          </div>
        )}
        hiddenContent={() => (
          <div className="expanding-card-content hidden">
            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
              <IconCard
                icon={EnrollmentForm}
                alt="Enrollment form"
                cardClass="download-card sarclisa"
                cardTitle="Enrollment form"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-program-enrollment-form.pdf"
                linkClass="download-icon"
                linkTitle="Download the enrollment form"
                linkId="hcp-sarclisa-resource-enroll-form"
                isBlur={true}
              />
              <IconCard
                icon={spanishEnrollmentForm}
                alt="Spanish Enrollment Form Icon"
                cardClass="download-card sarclisa"
                cardTitle="Spanish enrollment form"
                cardText=""
                isExternalLink={true}
                link="../../../pdfs/careassist-spanish-enrollment-form.pdf"
                linkClass="download-icon"
                linkTitle="Download the Copay proof of expense form"
                linkId="hcp-sarclisa-resource-spanish-enroll-form"
                isBlur={true}
              />
              <IconCard
              icon={Officesupportbrochure}
              alt="CareASSIST HCP Overview Brochure"
              cardClass="download-card sarclisa"
              cardTitle="CareASSIST HCP overview brochure"
              cardText=""
              hasButton={false}
              isExternalLink={true}
              link="../../../pdfs/careassist-patient-support-program-overview-resource-hcp.pdf"
              linkClass="download-icon"
              linkTitle="Download the enrollment form"
              isBlur={true}
              linkId="hcp-sarclisa-resource-overview-brochure"
            />
            </div>
            
            
          </div>
        )}
      />
      <ExpandingCard
        cardClass="full sarclisa"
        mainContent={() => (
          <div className="expanding-card-content">
            <h2>Financial and reimbursement support</h2>
          </div>
        )}
        hiddenContent={() => (
          <div className="expanding-card-content hidden">
            <h3>Financial tools</h3>
            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
              <IconCard
              icon={CopayProgramflashcard}
              alt="Copay Program flashcard"
              cardClass="download-card sarclisa"
              cardTitle="Copay Program flashcard"
              cardText=""
              hasButton={false}
              isExternalLink={true}
              link="../../../pdfs/careassist-copay-program-hcp-flashcard.pdf"
              linkClass="download-icon"
              linkTitle="Download the enrollment form"
              isBlur={true}
              linkId="hcp-sarclisa-resource-copay-program-flashcard"
            />
            <IconCard
              icon={PatientAssistanceProgramflashcard}
              alt="Patient Assistance Program flashcard"
              cardClass="download-card sarclisa"
              cardTitle="Patient Assistance Program flashcard"
              cardText=""
              hasButton={false}
              isExternalLink={true}
              link="../../../pdfs/careassist-pap-flashcard.pdf"
              linkClass="download-icon"
              linkTitle="Download the enrollment form"
              isBlur={true}
              linkId="hcp-sarclisa-resource-pap-flashcard"
            />
            
            </div>
            <hr className="sarclisa only-mbl" />
            <h3>Reimbursement tools</h3>
            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
              <IconCard
                icon={Summarybenefitsflashcard}
                alt="Summary of benefits flashcard"
                cardClass="download-card sarclisa"
                cardTitle="Summary of benefits flashcard"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-summary-of-benefits-flashcard.pdf"
                linkClass="download-icon"
                linkTitle="Download the Summary of benefits flashcard"
                isBlur={true}
                linkId="hcp-sarclisa-resource-benefits-flashcard"
              />
              <IconCard
                icon={CMSannotatedform}
                alt="Sample CMS annotated form flashcard"
                cardClass="download-card sarclisa"
                cardTitle="Sample CMS annotated form flashcard"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-sample-annotated-cms-form-flashcard.pdf"
                linkClass="download-icon"
                linkTitle="Download the Sample CMS annotated form flashcard"
                isBlur={true}
                linkId="hcp-sarclisa-resource-annotated-form-flashcard"
              />
              <IconCard
                icon={Priorauthorizationchecklist}
                alt="Prior authorization checklist"
                cardClass="download-card sarclisa"
                cardTitle="Prior authorization checklist"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-prior-authorization-checklist.pdf"
                linkClass="download-icon"
                linkTitle="Download the Prior authorization checklist"
                isBlur={true}
                linkId="hcp-sarclisa-resource-prior-authorization-checklist"
              />
              <IconCard
                icon={Payerdenialflashcard}
                alt="Payer denial flashcard"
                cardClass="download-card sarclisa"
                cardTitle="Payer denial flashcard"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-payer-denial-flashcard.pdf"
                linkClass="download-icon"
                linkTitle="Download the Payer denial flashcard"
                isBlur={true}
                linkId="hcp-sarclisa-resource-payer-denial-flashcard"
              />
              <IconCard
              icon={sarclisaBillingandcodingguide}
              alt="SARCLISA billing and coding guide"
              cardClass="download-card sarclisa"
              cardTitle="SARCLISA billing and coding guide"
              cardText=""
              hasButton={false}
              isExternalLink={true}
              link="../../../pdfs/sarclisa-billing-coding-guide.pdf"
              linkClass="download-icon"
              linkTitle="Download the billing and coding guide"
              isBlur={true}
              linkId="hcp-sarclisa-resource-billing-coding-guide"
            />
            </div>
            <hr className="sarclisa only-mbl" />
            <h3>Sample letters</h3>
            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
              <IconCard
                icon={Letterofappealstemplate}
                alt="Letter of appeals template"
                cardClass="download-card sarclisa"
                cardTitle="Letter of appeals template"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-sample-letter-of-appeals.docx"
                linkClass="download-icon"
                linkTitle="Download the Letter of appeals template"
                isBlur={true}
                linkId="hcp-sarclisa-resource-letter-of-appeal-template"
              />
              <IconCard
                icon={Letterofmedicalnecessitytemplate}
                alt="Letter of medical necessity template"
                cardClass="download-card sarclisa"
                cardTitle="Letter of medical necessity template"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-sample-letter-of-medical-necessity.docx"
                linkClass="download-icon"
                linkTitle="Download the Letter of medical necessity template"
                isBlur={true}
                linkId="hcp-sarclisa-resource-medical-necessity-template"
              />
              <IconCard
                icon={Letterofmedicalexception}
                alt="Letter of medical exception"
                cardClass="download-card sarclisa"
                cardTitle="Letter of medical exception template"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-sample-letter-of-medical-exception.docx"
                linkClass="download-icon"
                linkTitle="Download the Template of medical exception template"
                isBlur={true}
                linkId="hcp-sarclisa-resource-medical-exception-template"
              />
            </div>
          </div>
        )}
      />
      <ExpandingCard
        cardClass="full sarclisa"
        mainContent={() => (
          <div className="expanding-card-content header">
            <h2>Online resources*</h2>
          </div>
        )}
        hiddenContent={() => (
          <div className="expanding-card-content hidden">
            <p className="mb-1">
              Certain patients may require information or assistance beyond what
              CareASSIST can offer. Below are links to external organizations
              that may be able to help. You or your patients may also call &nbsp;
                <a className="phone-number" href="tel:1-833-930-2273">1-833-WE+CARE</a> (<a className="phone-number" href="tel:1-833-930-2273">1-833-930-2273</a>) to learn more.
            </p>
            <div className="grid-cols-sm-1 grid-cols-2 gap-20">
            <IconCard
              alt="HealthCare"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="HealthCare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://healthcare.gov"
              modalLinkId="hcp-sarclisa-resource-healthcare-gov"
            />
            <IconCard
              alt="Medicaid.gov"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Medicaid.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicaid.gov"
              modalLinkId="hcp-sarclisa-resource-medicaid-gov"
            />
            <IconCard
              alt="Medicare.gov"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Medicare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicare.gov"
              modalLinkId="hcp-sarclisa-resource-medicare-gov"
            />
            <IconCard
              alt="Patient Advocate Foundation"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Patient Advocate Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.patientadvocate.org/"
              modalLinkId="hcp-sarclisa-resource-patientadvocate-org"
            />
            <IconCard
              alt="Patient Advocate Network Foundation"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Patient Advocate Network Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.panfoundation.org/"
              modalLinkId="hcp-sarclisa-resource-panfoundation-org"
            />
            <IconCard
              alt="American Cancer Society"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="American Cancer Society"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://cancer.org"
              modalLinkId="hcp-sarclisa-resource-cancer-org"
            />
            <IconCard
              alt="Cancer Support Community"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Cancer Support Community"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.cancersupportcommunity.org/"
              modalLinkId="hcp-sarclisa-resource-cancersupportcommunity-org"
            />
            <IconCard
              alt="National Comprehensive Cancer Network<sup>®</sup>"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://nccn.org"
              modalLinkId="hcp-sarclisa-resource-nccn-org"
            />
            <IconCard
              alt="CancerCare"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="CancerCare"
              cardText=""
              isModalLink={true}
              modalLink="https://www.cancercare.org/"
              modalLinkId="hcp-sarclisa-resource-cancercare-org"
            />
            <IconCard
              alt="FindHelp"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="FindHelp.org"
              cardText=""
              isModalLink={true}
              modalLink="https://www.FindHelp.org/"
              modalLinkId="hcp-sarclisa-resource-findhelp-org"
            />
            </div>
            <div className="important-info sarclisa pt-1">
              <p className="hang">*This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites. </p>
              <h4 className="m-t-0">INDICATION</h4>
              <p>
                SARCLISA (isatuximab-irfc) is indicated:
              </p>
              
                <p className="m-b-0 hang-20 p-l-20">
                 <span className="orange-bull bold">&bull;</span> In combination with pomalidomide and dexamethasone, for the treatment of adult patients with multiple myeloma who have received at least 2 prior therapies including lenalidomide and a proteasome inhibitor
                </p>
                <p className="hang-20 p-l-20">
                <span className="orange-bull bold">&bull;</span>  In combination with carfilzomib and dexamethasone, for the treatment of adult patients with relapsed or refractory multiple myeloma who have received 1 to 3 prior lines of therapy
                </p>
              
          
              <h4>IMPORTANT SAFETY INFORMATION</h4>

              <h4>CONTRAINDICATIONS</h4>
              <p>
              SARCLISA is contraindicated in patients with severe hypersensitivity to isatuximab-irfc or to any of its excipients.
              </p>
              <h4>WARNINGS AND PRECAUTIONS</h4>
              <p>
                <strong>Infusion-Related Reactions</strong>
              </p>
              <p>
                Serious infusion-related reactions (IRRs), including life-threatening anaphylactic reactions, have occurred with SARCLISA treatment. Severe signs and symptoms include cardiac arrest, hypertension, hypotension, bronchospasm, dyspnea, angioedema, and swelling.
              </p>
              <p>
                Based on ICARIA-MM, IRRs occurred in 38% of patients treated with SARCLISA, pomalidomide, and dexamethasone (Isa-Pd). All IRRs started during the first SARCLISA infusion and resolved on the same day in 98% of the cases.
              </p>
              <p>
                In IKEMA, infusion-related reactions occurred in 46% of patients treated with SARCLISA, carfilzomib, and dexamethasone (Isa-Kd). In the Isa-Kd arm, the infusion-related reactions occurred on the infusion day in 99% of episodes. In patients treated with Isa-Kd, 95% of those experiencing an infusion-related reaction experienced it during the first cycle of treatment. All infusion-related reactions resolved: within the same day in 74% of episodes, and the day after in 24% of episodes.
              </p>
              <p>
                The most common symptoms (≥5%) of an infusion-related reaction in ICARIA-MM and IKEMA (N=329) included dyspnea, cough, nasal congestion, and nausea. Anaphylactic reactions occurred in less than 1% of patients. To decrease the risk and severity of IRRs, premedicate patients prior to SARCLISA infusion with acetaminophen, H<sub style={{lineHeight: "14px"}}>2</sub> antagonists, diphenhydramine or equivalent, and dexamethasone.
              </p>
              <p>
                Monitor vital signs frequently during the entire SARCLISA infusion. For patients with grade ≥2 reactions, interrupt SARCLISA infusion and provide appropriate medical management. For patients with grade 2 or grade 3 reactions, if symptoms improve to grade ≤1, restart SARCLISA infusion at half of the initial infusion rate, with supportive care as needed, and closely monitor patients. If symptoms do not recur after 30 minutes, the infusion rate may be increased to the initial rate, and then increased incrementally. In case symptoms do not improve to grade ≤1 after interruption of SARCLISA infusion, persist or worsen despite appropriate medications, or require hospitalization, permanently discontinue SARCLISA and institute appropriate management. Permanently discontinue SARCLISA if an anaphylactic reaction or life-threatening (grade 4) IRR occurs and institute appropriate management.
              </p>
              <p>
                <strong>
                  Neutropenia
                </strong>
              </p>
              <p>
                SARCLISA may cause neutropenia.
              </p>
              <p>
              In patients treated with Isa-Pd, neutropenia occurred in 96% of patients and grade 3-4 neutropenia occurred in 85% of patients. Neutropenic complications occurred in 30% of patients, including febrile neutropenia (12%) and neutropenic infections (25%), defined as infection with concurrent grade ≥3 neutropenia. The most frequent neutropenic infections included infections of the upper respiratory tract (10%), lower respiratory tract (9%), and urinary tract (3%).
              </p>
              <p>
              In patients treated with Isa-Kd, neutropenia occurred in 55% of patients, with grade 3-4 neutropenia in 19% of patients (grade 3 in 18% and grade 4 in 1.7%). Neutropenic complications occurred in 2.8% of patients, including febrile neutropenia (1.1%) and neutropenic infections (1.7%).
              </p>
              <p>
              Monitor complete blood cell counts periodically during treatment. Consider the use of antibacterial and antiviral prophylaxis during treatment. Monitor patients with neutropenia for signs of infection. In case of grade 4 neutropenia, delay SARCLISA dose until neutrophil count recovery to at least 1 × 10<sup style={{lineHeight: "14px"}}>9</sup>/L, and provide supportive care with growth factors, according to institutional guidelines. No dose reductions of SARCLISA are recommended.
              </p>
              <p>
                <strong>Second Primary Malignancies</strong>
              </p>
              <p>The incidence of second primary malignancies is increased in patients treated with SARCLISA-containing regimens. The overall incidence of second primary malignancies in all the SARCLISA-exposed patients was 4.1%.</p>
              <p>In ICARIA-MM, at a median follow-up time of 52 months, second primary malignancies occurred in 7% of patients in the Isa-Pd arm and in 2% of patients in the Pd arm.</p>
              <p>In the ongoing IKEMA study, at a median follow-up time of 21 months, second primary malignancies occurred in 7% of patients in the Isa-Kd arm and in 4.9% of patients in the Kd arm.</p>
              <p>The most common (≥1%) second primary malignancies in ICARIA-MM and IKEMA (N=329) included skin cancers (5% with SARCLISA-containing regimens and 2.6% with comparative regimens) and solid tumors other than skin cancer (3% with SARCLISA-containing regimens and 1.8% with comparative regimens). All patients with non-melanoma skin cancer continued treatment after resection of the skin cancer.</p>
              <p>Monitor patients for the development of second primary malignancies.</p>
              <p>
                <strong> 
                  Laboratory Test Interference 
                </strong>  
              </p>
                <p>
                  <span style={{textDecoration: "underline"}}>Interference with Serological Testing (Indirect Antiglobulin Test)</span> 
                </p>
              <p> SARCLISA binds to CD38 on red blood cells (RBCs) and may result in a false-positive indirect antiglobulin test (indirect Coombs test). The indirect antiglobulin test was positive during Isa-Pd treatment in 68% of the tested patients, and during Isa-Kd treatment in 63% of patients. In patients with a positive indirect antiglobulin test, blood transfusions were administered without evidence of hemolysis. ABO/RhD typing was not affected by SARCLISA treatment.</p>
              <p>Before the first SARCLISA infusion, conduct blood type and screen tests on SARCLISA-treated patients. Consider phenotyping prior to starting SARCLISA treatment. If treatment with SARCLISA has already started, inform the blood bank that the patient is receiving SARCLISA and that SARCLISA interference with blood compatibility testing can be resolved using dithiothreitol-treated RBCs. If an emergency transfusion is required, non–cross-matched ABO/RhD-compatible RBCs can be given as per local blood bank practices.</p>
              <p><span style={{textDecoration: "underline"}}>Interference with Serum Protein Electrophoresis and Immunofixation Tests
                  </span></p>
              <p>SARCLISA is an IgG kappa monoclonal antibody that can be incidentally detected on both serum protein electrophoresis and immunofixation assays used for the clinical monitoring of endogenous M-protein. This interference can impact the accuracy of the determination of complete response in some patients with IgG kappa myeloma protein.</p>
              <p>
                <strong>
                  Embryo-Fetal Toxicity
                </strong>
              </p>
              <p>Based on the mechanism of action, SARCLISA can cause fetal harm when administered to a pregnant woman. SARCLISA may cause fetal immune cell depletion and decreased bone density. Advise pregnant women of the potential risk to a fetus. Advise females with reproductive potential to use an effective method of contraception during treatment with SARCLISA and for 5 months after the last dose. The combination of SARCLISA with pomalidomide is contraindicated in pregnant women because pomalidomide may cause birth defects and death of the unborn child. Refer to the pomalidomide prescribing information on use during pregnancy.</p>
              <h4>ADVERSE REACTIONS</h4>
              <p>In combination with pomalidomide and dexamethasone: The most common adverse reactions (≥20%) were upper respiratory tract infection, infusion-related reactions, pneumonia, and diarrhea. The most common hematology laboratory abnormalities (≥80%) were decreased hemoglobin, decreased neutrophils, decreased lymphocytes, and decreased platelets.</p>
              <p>In combination with carfilzomib and dexamethasone: The most common adverse reactions (≥20%) were upper respiratory tract infection, infusion-related reactions, fatigue, hypertension, diarrhea, pneumonia, dyspnea, insomnia, bronchitis, cough, and back pain. The most common hematology laboratory abnormalities (≥80%) were decreased hemoglobin, decreased lymphocytes, and decreased platelets.</p>
              <p>Serious adverse reactions occurred in 62% of patients receiving Isa-Pd. Serious adverse reactions in >5% of patients who received Isa-Pd included pneumonia (26%), upper respiratory tract infections (7%), and febrile neutropenia (7%). Fatal adverse reactions occurred in 11% of patients (those that occurred in more than 1% of patients were pneumonia and other infections [3%]).</p>
              <p>Serious adverse reactions occurred in 59% of patients receiving Isa-Kd. The most frequent serious adverse reactions in >5% of patients who received Isa-Kd were pneumonia (25%) and upper respiratory tract infections (9%). Adverse reactions with a fatal outcome during treatment were reported in 3.4% of patients in the Isa-Kd group (those occurring in more than 1% of patients were pneumonia occurring in 1.7% and cardiac failure in 1.1% of patients).</p>
              <h4>USE IN SPECIAL POPULATIONS</h4>
              <p>Because of the potential for serious adverse reactions in the breastfed child from isatuximab-irfc administered in combination with Pd, advise lactating women not to breastfeed during treatment with SARCLISA.</p>

             
            </div>
          </div>
        )}
      />
      </blockquote>
      {/* On Sarclisa there is no ISI bc there is no mention of cancer */}

      <div className="mobile-m-t-40">
        <PrescribingInfo linkId="hcp-sarclisa-resource-prescribing-information" link="https://products.sanofi.us/Sarclisa/sarclisa.pdf" hideBoxed="hide-boxed"/>
        <HaveQuestions icon={QuestionsIcon} />
      </div>
    </section>
    <div className="back-to-top-container">
        <BackToTop />
      </div>
  </Layout>
);

export default pageContent